import React, { useEffect, useState, useRef } from "react";
import { TweenMax, TimelineMax, Power0, Power1 } from "gsap/TweenMax";
import { Draggable } from "gsap/Draggable";
import styled from "styled-components";
import Img from "gatsby-image";

import copy from "src/copy";
import Right from "src/images/chevrons/right-chevron.png";
import Left from "src/images/chevrons/left-chevron.png";

import useWindowSize from "src/hooks/useWindowSize";

const Container = styled.div`
  height: 380px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  perspective: 700px;
  position: relative;
`;

const Chevron = styled.img`
  font-family: "URWAccidalia", sans-serif;
  cursor: pointer;
  position: absolute;
  ${props => (props.left ? "left: 18%" : "right: 18%")};
  width: 50px;
  z-index: 9999;
  transform: translateZ(100px);
  opacity: 0;
  padding: 15px;
  ${props => (props.clickable ? null : "pointer-events: none")};

  @media (max-width: 600px) {
    ${props => (props.left ? "left: -12px" : "right: -12px")};
    width: 35px;
  }
`;

const Gallery = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ImgWrapper = styled.div`
  transform-style: preserve-3d;
  max-height: 380px;
  max-width: 300px;
`;

const Heading = styled.h1`
  font-family: "URWAccidalia", sans-serif;
  font-size: 28px;
  margin: 0 0 10px 0;
  color: white;
  @media (max-width: 500px) {
    font-size: 24px;
  }
  @media (max-width: 350px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-family: "Univers", sans-serif;
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  color: white;
  width: 550px;

  @media only screen and (max-width: 700px) {
    width: 400px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 430px;

  @media only screen and (max-width: 700px) {
    top: 355px;
  }
  @media (max-width: 350px) {
    top: 310px;
  }
`;

const { guests } = copy;

const Guests = ({ data }) => {
  // Controls the middle of the carousel
  const [middle, setMiddle] = useState(0);
  const guestTimelineRef = useRef();
  const { width } = useWindowSize();

  useEffect(() => {
    const guestTimeline = new TimelineMax({ paused: true, repeat: -1 });
    if (width > 700) {
      guests.map((guest, i) =>
        guestTimeline.fromTo(
          `#guest${i}`,
          guests.length,
          { x: `${(150 * guests.length) / 2 - 75}`, ease: Power0.easeNone },
          {
            x: `-${(150 * guests.length) / 2 - 75 + 150}`,
            ease: Power0.easeNone,
          },
          0
        )
      );
    } else if (width > 350) {
      guests.map((guest, i) =>
        guestTimeline.fromTo(
          `#guest${i}`,
          guests.length,
          { x: `${(100 * guests.length) / 2 - 50}`, ease: Power0.easeNone },
          {
            x: `-${(100 * guests.length) / 2 - 50 + 100}`,
            ease: Power0.easeNone,
          },
          0
        )
      );
    } else {
      guests.map((guest, i) =>
        guestTimeline.fromTo(
          `#guest${i}`,
          guests.length,
          { x: `${(75 * guests.length) / 2 - 37.5}`, ease: Power0.easeNone },
          {
            x: `-${(75 * guests.length) / 2 - 37.5 + 75}`,
            ease: Power0.easeNone,
          },
          0
        )
      );
    }
    guestTimelineRef.current = guestTimeline;
    // Create Draggable instance
    Draggable.create("#gallery", {
      activeCursor: "grabbing",
      cursor: "grab",
      bounds: {
        minX: 0,
        maxX: 0,
        minY: 0,
        maxY: 0,
      },
      onPress() {
        this.prev = this.pointerX;
      },
      onRelease() {
        if (this.prev - this.pointerX > 0.5) {
          rightChevronHandler();
        } else if (this.pointerX - this.prev > 0.5) {
          leftChevronHandler();
        }
      },
    });
  }, []);

  useEffect(() => {
    guestTimelineRef.current
      .tweenTo(middle, { ease: Power1.easeInOut })
      .duration(0.5);

    const panel = document.querySelector("#gallery");
    const parent = Array.from(panel.childNodes);

    //Add "left" className to every panel left of active
    for (let i = 0; i < middle; i++) {
      parent[i].classList.add("left");

      parent[i].classList.remove("close-left");
      parent[i].classList.remove("close-right");

      if (i === middle - 1) {
        parent[i].classList.add("close-left");
        parent[i].classList.remove("left");
      }

      parent[i].classList.remove("right");
    }

    //Add "right" className to every panel right of active
    for (let i = middle + 1; i < parent.length; i++) {
      parent[i].classList.add("right");

      parent[i].classList.remove("close-left");
      parent[i].classList.remove("close-right");

      if (i === middle + 1) {
        parent[i].classList.add("close-right");
        parent[i].classList.remove("right");
      }

      parent[i].classList.remove("left");
    }

    //Remove "left" and "right" className on active panel
    for (let i = 0; i < parent.length; i++) {
      if (i === middle) {
        parent[middle].classList.remove(
          "close-right",
          "close-left",
          "left",
          "right",
          "last-left",
          "last-right"
        );
      }
      if (i === middle && i === 0) {
        parent[middle].classList.add("last-left");
      }
      if (i === middle && i === parent.length - 1) {
        parent[middle].classList.add("last-right");
      }
    }
  }, [middle]);

  useEffect(() => {
    // Tween middle value
    TweenMax.to(`#guest${middle}`, 0.5, {
      rotationY: "0",
      filter: "brightness(100%)",
      z: 50,
      zIndex: 50,
      transformOrigin: "50% 50%",
      margin: 0,
    });

    TweenMax.to(".last-left", 0.5, {
      marginLeft: width > 700 ? 200 : width > 350 ? 133 : 100,
    });

    TweenMax.to(".last-right", 0.5, {
      marginRight: width > 700 ? 200 : width > 350 ? 133 : 100,
    });

    //Tween panels
    TweenMax.to(".left", 0.5, {
      rotationY: 80,
      filter: "brightness(" + 25 + "%" + ")",
      transformOrigin: "right 50%",
      z: -150,
      zIndex: -50,
      marginRight: width > 700 ? -150 : width > 350 ? -100 : -75,
      marginLeft: 0,
    });

    TweenMax.to(".right", 0.5, {
      rotationY: -80,
      filter: "brightness(" + 25 + "%" + ")",
      transformOrigin: "left 50%",
      z: -150,
      zIndex: -50,
      marginLeft: width > 700 ? -150 : width > 350 ? -100 : -75,
      marginRight: 0,
    });

    TweenMax.to(".close-left", 0.5, {
      rotationY: 60,
      filter: "brightness(" + 50 + "%" + ")",
      transformOrigin: "right 50%",
      z: -150,
      zIndex: 0,
      margin:
        width > 700
          ? "0px 0px 0px 50px"
          : width > 350
          ? "0px 0px 0px 33px"
          : "0px 0px 0px 25px",
    });

    TweenMax.to(".close-right", 0.5, {
      rotationY: -60,
      filter: "brightness(" + 50 + "%" + ")",
      transformOrigin: "left 50%",
      z: -150,
      zIndex: 0,
      margin:
        width > 700
          ? "0px 50px 0px 0px"
          : width > 350
          ? "0px 33px 0px 0px"
          : "0px 25px 0px 0px",
    });

    TweenMax.to(".visible", 0.5, { autoAlpha: 1 });
    TweenMax.to(".hidden", 0.5, { autoAlpha: 0 });
  });

  const leftChevronHandler = () => {
    setMiddle(prev => {
      if (prev === 0) {
        return guests.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const rightChevronHandler = () => {
    setMiddle(prev => {
      if (prev === guests.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  return (
    <Container>
      <Chevron
        className={`chevron ${middle !== 0 ? "visible" : "hidden"}`}
        onClick={leftChevronHandler}
        left
        src={Left}
        clickable={middle !== 0}
      />

      <Chevron
        className={`chevron ${
          middle !== guests.length - 1 ? "visible" : "hidden"
        }`}
        onClick={rightChevronHandler}
        right
        src={Right}
        clickable={middle !== guests.length - 1}
      />

      <Gallery id="gallery">
        {data.map((guest, i) => {
          return (
            <ImgWrapper key={`guest${i}`} id={`guest${i}`}>
              {width < 700 ? (
                width < 350 ? (
                  <Img fixed={guest.node.tiny.fixed}></Img>
                ) : (
                  <Img fixed={guest.node.small.fixed}></Img>
                )
              ) : (
                <Img fixed={guest.node.large.fixed}></Img>
              )}
            </ImgWrapper>
          );
        })}
      </Gallery>
      <TextContainer>
        <Heading>{guests[middle].name}</Heading>
        <Description>{guests[middle].bio}</Description>
      </TextContainer>
    </Container>
  );
};

export default Guests;
