import React, { useEffect, useState, useContext } from "react";
import { TweenMax, TimelineMax, Power4, ScrollToPlugin } from "gsap/all";
import styled from "styled-components";
import { NavbarContext } from "src/contexts/navbarContext";
import useWindowSize from "src/hooks/useWindowSize";
import Loadable from "react-loadable";
import SEO from "src/components/seo.js";

import Guests from "src/components/guests";

import { graphql } from "gatsby";
import Img from "gatsby-image";

const Viewport = styled.div`
  height: 20000px;

  @media (max-width: 500px) {
    height: 10000px;
  }
`;

const plugins = [ScrollToPlugin];

const ScrollHintHeading = styled.h2`
  font-family: "URWAccidalia";
  font-size: 24px;
  color: white;
  z-index: 69421;
  position: fixed;
  top: 5%;
  left: 35%;
  text-align: center;
  width: 30%;
  opacity: 0;
  pointer-events: none;
  line-height: 1.2;

  @media (max-width: 500px) {
    font-size: 18px;
    top: 6%;
    left: 23%;
  }
`;

const ScrollHintSubHeading = styled.h2`
  font-family: "URWAccidalia";
  font-size: 14px;
  color: white;
  z-index: 69421;
  position: fixed;
  top: 3%;
  left: 35%;
  text-align: center;
  width: 30%;
  opacity: 0;
  pointer-events: none;
  line-height: 1.2;

  @media (max-width: 500px) {
    font-size: 10px;
    top: 4%;
    left: 23%;
  }
`;

const TitleContainer = styled.div`
  height: 100vh;
  position: fixed;
  align-items: center;
  font-family: "URWAccidalia", sans-serif;
  font-size: 42pt;
  z-index: 1000;
  color: transparent;

  @media (max-width: 1150px) {
    display: none;
  }
`;

const SectionTitle = styled.h1`
  writing-mode: vertical-rl;
  -webkit-text-stroke: 1px white;
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 1000;
  margin-left: 16px;
`;

const Scene3DContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 500px;
  perspective-origin: 50% 50%;
  transform: translate3d(0px, 0px, 0px);
`;

const Scene3D = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transform: translateZ(0px);
`;

const SectionTitleContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transform: ${props => `translate3D(0%, 0%, -${props.z}px)`};
  opacity: ${props => props.opacity};
  color: white;
  text-align: center;
`;

const SectionTitleWrapper = styled.div`
  width: 450px;
  padding: 35px;
`;

const SectionTitleText = styled.h2`
  font-family: "URWAccidalia", sans-serif;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const QuoteContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transform: ${props => `translate3D(0%, 0%, -${props.z}px)`};
  opacity: ${props => props.opacity};
  color: white;
  text-align: center;
`;

const QuoteWrapper = styled.div`
  width: 450px;
  padding: 35px;
`;

const Quote = styled.h2`
  font-family: "URWAccidalia", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 25px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Author = styled.p`
  font-family: "Univers", sans-serif;
`;

const Paragraph = styled.p`
  font-family: "Univers", sans-serif;
  margin-left: ${props => (props.direction === "row" ? "10px" : "auto")};
  margin-right: ${props => (props.direction === "row" ? "0px" : "auto")};
  width: 450px;
  color: white;
  transform: translateZ(0);
  backface-visibility: hidden;
  margin-bottom: 10px;
  text-align: ${props => props.textalign};
  text-align-last: ${props => props.center && "center"};
  line-height: 1.2;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 500px) {
    text-align: center;
    font-size: 12px;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transform: ${props => `translate3D(0%, 0%, -${props.z}px)`};
  opacity: ${props => props.opacity};
  margin-top: 60px;

  @media (max-width: 1200px) {
    margin-top: 125px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  @media (max-width: 550px) {
    margin-top: 75px;
  }

  @media (min-height: 900px) and (max-width: 800px) {
    margin-top: 250px;
  }
  @media (min-height: 900px) and (max-width: 1400px) {
    margin-top: 150px;
  }
  @media (min-height: 1300px) and (max-width: 1100px) {
    margin-top: 350px;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transform: ${props => `translate3D(0%, 0%, -${props.z}px)`};
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: 0;

  img {
    width: 200px;
  }

  .lamar {
    position: relative;
    right: 300px;
  }

  .talk {
    position: relative;
    right: 50px;
    top: 300px;
  }

  .looking {
    position: relative;
    right: 175px;
    top: 150px;
  }

  .sitting {
    position: relative;
    right: 275px;
    top: 150px;
  }

  .room {
    position: relative;
    left: 200px;
    top: 200px;
  }

  .sitting-squad {
    position: relative;
    left: 400px;
    top: 400px;
  }

  .smile {
    position: relative;
    left: 400px;
    top: 400px;
  }

  .hugh {
    position: relative;
    right: 400px;
    top: 0;
  }

  .in-hxouse {
    position: relative;
    left: 650px;
    top: 50px;
  }

  .weeknd {
    position: relative;
    left: 650px;
    bottom: 300px;
  }

  .stickynotes {
    position: relative;
    right: 500px;
    bottom: 300px;
  }

  .standing {
    position: relative;
    left: 50px;
    bottom: 200px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      margin-bottom: 15px;
    }

    .paragraph2-img {
      margin-bottom: 20px;
    }

    .lamar {
      right: 200px;
    }

    .talk {
      right: 50px;
      top: 300px;
    }

    .looking {
      right: 100px;
      top: 150px;
    }

    .sitting {
      right: 150px;
      top: 150px;
    }

    .room {
      left: 200px;
      top: 200px;
    }

    .sitting-squad {
      left: 300px;
      top: 400px;
    }

    .smile {
      left: 275px;
      top: 400px;
    }

    .hugh {
      left: 100px;
      top: 600px;
    }

    .in-hxouse {
      left: 450px;
      top: 50px;
    }

    .weeknd {
      left: 450px;
      bottom: 300px;
    }

    .stickynotes {
      right: 350px;
      bottom: 300px;
    }

    .standing {
      left: 50px;
      bottom: 200px;
    }
  }
`;

// const loader = () => <div>Loading threejs...</div>;

// const TeamCubeLoadable = Loadable({
//   loader: () => import("src/components/teamCube"), // imports the component with the three.js and allows use of it safely
//   loading: loader,
// });

const IndexPage = ({ data }) => {
  const {
    jumpValue,
    changeSubheading,
    changeIsAnimatingTrue,
    changeIsAnimatingFalse,
    isAnimating,
    isClicked,
    changeAnimationComplete,
    animationComplete,
  } = useContext(NavbarContext);
  const [subheadingPos, setSubheadingPos] = useState(0);

  let scrollTotal = 0;
  let scrollPosY = 0;
  const { width, height } = useWindowSize();

  useEffect(() => {
    const yPosition =
      jumpValue * (document.body.scrollHeight - window.innerHeight);
    TweenMax.to(window, 1, {
      scrollTo: yPosition,
      ease: Power4.easeInOut,
      onStart: function() {
        changeIsAnimatingTrue();
        changeAnimationComplete(false);
      },
      onComplete: function() {
        changeIsAnimatingFalse();
        changeAnimationComplete(true);
      },
    });
  }, [jumpValue, isClicked]);

  useEffect(() => {
    if (width > 1200) {
      if (height < 700) {
        TweenMax.set("#guestCarousel", { y: -100 });
      } else {
        TweenMax.set("#guestCarousel", { y: 25 });
      }
    } else {
      TweenMax.set("#guestCarousel", { y: -50 });
    }
  }, [height]);

  useEffect(() => {
    if (animationComplete === false && isAnimating === true) {
      changeIsAnimatingFalse();
    }
    if (animationComplete === false && isAnimating === false) {
      changeAnimationComplete(true);
    }
    if (subheadingPos === 0) {
      changeSubheading("vision");
    } else if (subheadingPos === 0.12) {
      changeSubheading("story");
    } else if (subheadingPos === 0.35) {
      changeSubheading("team");
    } else {
      changeSubheading("guests");
    }
  }, [subheadingPos]);

  useEffect(() => {
    const pulse1Tl = new TimelineMax({ paused: true, repeat: -1 });
    pulse1Tl.to("#hint1", 1, { color: "#828282" }, "0");
    pulse1Tl.to("#scrollHint1", 1, { color: "#828282" }, "0");
    pulse1Tl.to("#hint1", 1, { color: "#fff" }, "1");
    pulse1Tl.to("#scrollHint1", 1, { color: "#fff" }, "1");
    // const pulse2Tl = new TimelineMax({ paused: true, repeat: -1 });
    // pulse2Tl.to("#hint2", 1, { color: "#828282" }, "0");
    // pulse2Tl.to("#scrollHint2", 1, { color: "#828282" }, "0");
    // pulse2Tl.to("#hint2", 1, { color: "#fff" }, "1");
    // pulse2Tl.to("#scrollHint2", 1, { color: "#fff" }, "1");
    const runPulse1 = () => {
      pulse1Tl.play();
    };
    const runPulse2 = () => {
      // pulse2Tl.play();
    };
    const changeScrolled1 = () => {
      TweenMax.set("#scrollHint1", { display: "none" });
      TweenMax.set("#hint1", { display: "none" });
      pulse1Tl.pause();
    };
    const changeScrolled2 = () => {
      // TweenMax.set("#scrollHint2", { display: "none" });
      // TweenMax.set("#hint2", { display: "none" });
      // pulse2Tl.pause();
    };
    TweenMax.to("#scrollHint1", 1, {
      autoAlpha: 1,
      delay: 1,
      onComplete: runPulse1,
    });
    TweenMax.to("#hint1", 1, { autoAlpha: 1, delay: 1 });

    TweenMax.to("#paragraph1", 1.25, { autoAlpha: 1, ease: Power4.easeInOut });
    TweenMax.to("#TitleContainer", 1.25, {
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    TweenMax.set(".titles", { autoAlpha: 0 });
    TweenMax.fromTo(
      "#SectionTitle",
      1,
      { autoAlpha: 0 },
      {
        top: window.innerHeight / 3,
        autoAlpha: 1,
        ease: Power4.easeInOut,
      }
    );

    const tl = new TimelineMax({ paused: true });
    tl.add("transition1", "+=0");
    tl.to("#scrollHint1", 1, { autoAlpha: 0 }, "1");
    tl.to("#hint1", 1, { autoAlpha: 0 }, "1");
    tl.add(changeScrolled1, "2");

    tl.to("#paragraph1 p", 2, { autoAlpha: 0 }, "1");
    tl.to("#SectionTitle", 3, { y: window.innerHeight }, "1");

    if (width < 600) {
      tl.to("#paragraph2", 2, { autoAlpha: 1 }, "1.5");
      tl.to(".paragraph2-img", 0.75, { z: 300, autoAlpha: 0 }, "4");
      tl.to("#paragraph2 p", 3, { z: 150 }, "4");
      tl.to("#paragraph2 p", 2, { autoAlpha: 0 }, "4");
    } else {
      tl.to("#paragraph2", 2, { autoAlpha: 1 }, "1");
      tl.to(".paragraph2-img", 3, { z: 400, autoAlpha: 0 }, "3.5");
      tl.to("#paragraph2 p", 3, { z: 150 }, "3.5");
      tl.to("#paragraph2 p", 2, { autoAlpha: 0 }, "3.5");
    }

    tl.to("#VisionTitle", 6, { y: window.innerHeight }, "2");
    tl.to("#VisionTitle", 1, { autoAlpha: 1 }, "2");

    tl.to("#image1", 4, { autoAlpha: 1 }, "4");
    tl.to("#image2", 4, { autoAlpha: 1 }, "4");
    tl.to("#image3", 4, { autoAlpha: 1 }, "5");
    tl.to("#image4", 5, { autoAlpha: 1 }, "6");
    tl.to("#image5", 6, { autoAlpha: 1 }, "6");
    tl.to("#image7, #image8", 9, { autoAlpha: 1 }, "7");
    tl.to("#image9, #image10", 9, { autoAlpha: 1 }, "8");
    tl.to("#image6, #image11, #image12, #image13", 7, { autoAlpha: 1 }, "8");
    tl.to("#StoryTitle", 20, { y: window.innerHeight }, "7");
    tl.to("#StoryTitle", 1, { autoAlpha: 1 }, "7");

    // tl.to("#image1", 3, { autoAlpha: 0 }, "8");
    // tl.to("#image2", 4, { autoAlpha: 0 }, "8");
    // tl.to("#image3", 4, { autoAlpha: 0 }, "9");
    // tl.to("#image4", 3, { autoAlpha: 0 }, "11");
    if (width < 600) {
      tl.to("#image13", 1, { autoAlpha: 0 }, "20.15");
      // tl.to("#cube", 4, { autoAlpha: 1 }, "17");
    } else {
      tl.to("#image13", 1, { autoAlpha: 0 }, "21.5");
      // tl.to("#cube", 4, { autoAlpha: 1 }, "16.5");
    }

    // if (width < 800) {
    //   tl.to("#teamSectionTitle", 2, { autoAlpha: 1 }, 13);
    //   tl.to("#teamSectionTitle", 2, { autoAlpha: 0 }, 17);
    // }

    // if (width < 600) {
    // tl.to("#cube", 1, { autoAlpha: 0 }, "23.75");
    // tl.to("#scrollHint2", 1, { autoAlpha: 1, onComplete: runPulse2 }, "22");
    // tl.to("#hint2", 1, { autoAlpha: 1 }, "22");
    // tl.to("#scrollHint2", 2, { autoAlpha: 0 }, "25");
    // tl.to("#hint2", 2, { autoAlpha: 0 }, "25");
    // tl.add(changeScrolled2, "27");
    // } else {
    // tl.to("#cube", 2, { autoAlpha: 0 }, "21");
    // tl.to("#scrollHint2", 1, { autoAlpha: 1, onComplete: runPulse2 }, "19");
    // tl.to("#hint2", 1, { autoAlpha: 1 }, "19");
    // tl.to("#scrollHint2", 2, { autoAlpha: 0 }, "25");
    // tl.to("#hint2", 2, { autoAlpha: 0 }, "25");
    // tl.add(changeScrolled2, "27");
    // }
    // tl.to("#TeamTitle", 12, { y: window.innerHeight }, "17");
    // tl.to("#TeamTitle", 1, { autoAlpha: 1 }, "17");

    if (width < 800) {
      tl.to("#guestsSectionTitle", 1, { autoAlpha: 1 }, "20.5");
      tl.to("#guestsSectionTitle", 1, { autoAlpha: 0 }, "23");
    }

    if (width < 600) {
      tl.to("#guestCarousel", 3, { autoAlpha: 1 }, "23");
      tl.to("#guestCarousel", 3, { autoAlpha: 0 }, "28");
      tl.to("#quote1", 10, { autoAlpha: 1 }, "29.5");
    } else {
      tl.to("#guestCarousel", 4, { autoAlpha: 1 }, "22.25");
      tl.to("#guestCarousel", 4, { autoAlpha: 0 }, "27.4");
      tl.to("#quote1", 10, { autoAlpha: 1 }, "30");
    }
    tl.to("#GuestTitle", 12, { y: window.innerHeight }, "24");
    tl.to("#GuestTitle", 1, { autoAlpha: 1 }, "24");

    tl.to("#scene3D", 45, { z: "4250" }, "transition1");

    const handleScroll = () => {
      requestAnimationFrame(updateTimeline);
    };

    const updateTimeline = () => {
      scrollTotal = document.body.scrollHeight - window.innerHeight;
      scrollPosY = window.scrollY;

      const posPercent = Math.abs(scrollPosY / scrollTotal);

      if (posPercent < 0.1) {
        setSubheadingPos(0);
      } else if (posPercent < 0.49) {
        setSubheadingPos(0.12);
      } else if (posPercent < 0.5) {
        setSubheadingPos(0.35);
      } else {
        setSubheadingPos(0.66);
      }
      tl.progress(posPercent);
    };

    window.addEventListener("scroll", handleScroll, true);
    // window.addEventListener("scroll", updateTimeline);
    // window.addEventListener("scroll", throttle(updateTimeline, 20));

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <SEO title="HXOUSE · Home" />
      <ScrollHintSubHeading id="hint1">TO INTERACT</ScrollHintSubHeading>
      <ScrollHintHeading id="scrollHint1">SCROLL</ScrollHintHeading>

      {/* <ScrollHintSubHeading id="hint2">TO INTERACT</ScrollHintSubHeading>
      <ScrollHintHeading id="scrollHint2">
        {width < 800 ? `TAP` : `DRAG`}
      </ScrollHintHeading> */}

      <TitleContainer id="TitleContainer">
        <SectionTitle id="SectionTitle">HXOUSE</SectionTitle>
        <SectionTitle id="VisionTitle" className="titles">
          VISION
        </SectionTitle>
        <SectionTitle id="StoryTitle" className="titles">
          STORY
        </SectionTitle>
        <SectionTitle id="TeamTitle" className="titles">
          TEAM
        </SectionTitle>
        <SectionTitle id="GuestTitle" className="titles">
          EDUCATORS
        </SectionTitle>
      </TitleContainer>
      <Viewport>
        <Scene3DContainer>
          <Scene3D id="scene3D">
            <ContentContainer
              id="paragraph1"
              opacity={0}
              z="0"
              direction="column"
            >
              <Paragraph direction="column" textalign="center">
                HXOUSE is a Toronto-based, globally focused think-center.
                Serving its community as an incubator and accelerator, it helps
                to foster innovation and opportunity for creative entrepreneurs.
              </Paragraph>
              <Paragraph center direction="column" textalign="center">
                HXOUSE embodies the practice of cross-disciplinary learning,
                anchored in the core belief that by thinking outside your
                discipline, you can learn how to evolve your craft. Rather than
                traditional education, HXOUSE seeks to empower through
                mentorship and communication, engaging the creative mind through
                journeys and frank discussions surrounding the process and
                growth across all creative sectors.
              </Paragraph>
            </ContentContainer>

            <ContentContainer
              id="paragraph2"
              opacity={0}
              z="650"
              direction="row"
            >
              <Img
                className="paragraph2-img"
                fixed={data.emptyroom.childImageSharp.fixed}
              />
              <Paragraph direction="row" textalign="left">
                The goal of HXOUSE is to remedy the lack of attention currently
                offered to the creative arts and tech communities. Our mission
                is to always ensure that access to critical networks and
                opportunities needed to build sustainable, successful businesses
                are never hindered based on economic background or social class.
              </Paragraph>
            </ContentContainer>

            <ContentContainer
              id="image1"
              opacity={0}
              z="1800"
              direction="column"
            >
              <Img
                className="lamar"
                fixed={data.construction.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer
              id="image2"
              opacity={0}
              z="2000"
              direction="column"
            >
              <Img
                className="standing"
                fixed={data.bella.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image3" opacity={0} z="2000" direction="row">
              <Img className="talk" fixed={data.team.childImageSharp.fixed} />
            </ContentContainer>

            <ContentContainer id="image4" opacity={0} z="2100" direction="row">
              <Img
                className="sitting"
                fixed={data.groupphoto.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image5" opacity={0} z="2200" direction="row">
              <Img className="smile" fixed={data.lamar.childImageSharp.fixed} />
            </ContentContainer>

            <ContentContainer id="image6" opacity={0} z="2400" direction="row">
              <Img className="hugh" fixed={data.crowd.childImageSharp.fixed} />
            </ContentContainer>

            <ContentContainer id="image7" opacity={0} z="2500" direction="row">
              <Img
                className="weeknd"
                fixed={data.dorsey.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image8" opacity={0} z="2500" direction="row">
              <Img
                className="stickynotes"
                fixed={data.workshop.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image9" opacity={0} z="2600" direction="row">
              <Img
                className="looking"
                fixed={data.room.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image10" opacity={0} z="2600" direction="row">
              <Img className="room" fixed={data.trish.childImageSharp.fixed} />
            </ContentContainer>

            <ContentContainer id="image11" opacity={0} z="2800" direction="row">
              <Img
                className="sitting-squad"
                fixed={data.surface.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image12" opacity={0} z="2800" direction="row">
              <Img
                className="in-hxouse"
                fixed={data.jackets.childImageSharp.fixed}
              />
            </ContentContainer>

            <ContentContainer id="image13" opacity={0} z="2800" direction="row">
              <Img
                className="listen"
                fixed={data.lamarspeaking.childImageSharp.fixed}
              />
            </ContentContainer>

            {/* {width < 800 ? (
              <SectionTitleContainer id="teamSectionTitle" opacity={0} z="3000">
                <SectionTitleWrapper>
                  <SectionTitleText>TEAM</SectionTitleText>
                </SectionTitleWrapper>
              </SectionTitleContainer>
            ) : (
              <></>
            )} */}

            {/* <ContentContainer id="cube" opacity={0} z="3800" direction="row">
              <TeamCubeLoadable />
            </ContentContainer> */}

            {width < 800 ? (
              <SectionTitleContainer
                id="guestsSectionTitle"
                opacity={0}
                z="3100"
              >
                <SectionTitleWrapper>
                  <SectionTitleText>EDUCATORS</SectionTitleText>
                </SectionTitleWrapper>
              </SectionTitleContainer>
            ) : null}

            <SliderContainer id="guestCarousel" opacity={0} z="3800">
              <Guests data={data.guests.edges} />
            </SliderContainer>

            <QuoteContainer id="quote1" opacity={0} z="4250">
              <QuoteWrapper>
                <Quote>
                  "We came to the conclusion that the system was broken and we
                  didn't want to complain about it. We just wanted to reverse
                  everything we were taught and change the game."
                </Quote>
                <Author>
                  — La Mar Taylor, Cofounder and Creative Director at HXOUSE
                </Author>
              </QuoteWrapper>
            </QuoteContainer>
          </Scene3D>
        </Scene3DContainer>
      </Viewport>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    emptyroom: file(relativePath: { eq: "hxouse/emptyroom.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    construction: file(relativePath: { eq: "hxouse/construction.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bella: file(relativePath: { eq: "hxouse/bella.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team: file(relativePath: { eq: "hxouse/team.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    groupphoto: file(relativePath: { eq: "hxouse/groupphoto.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lamar: file(relativePath: { eq: "hxouse/lamar.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    crowd: file(relativePath: { eq: "hxouse/crowd.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    dorsey: file(relativePath: { eq: "hxouse/dorsey.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    workshop: file(relativePath: { eq: "hxouse/workshop.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    trish: file(relativePath: { eq: "hxouse/trish.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    room: file(relativePath: { eq: "hxouse/room.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    surface: file(relativePath: { eq: "hxouse/surface.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jackets: file(relativePath: { eq: "hxouse/jackets.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lamarspeaking: file(relativePath: { eq: "hxouse/lamarspeaking.jpg" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    guests: allFile(
      filter: {
        relativePath: { regex: "/guests/" }
        extension: { regex: "/jpg|jpeg/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          tiny: childImageSharp {
            fixed(width: 150, height: 190, cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
          small: childImageSharp {
            fixed(width: 200, height: 253, cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
          large: childImageSharp {
            fixed(width: 300, height: 380, cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
          relativePath
        }
      }
    }
  }
`;
